// colors
$primary: #14728f;
$linkcolor: #aa03a4;
$dark-300: #5b606b;
$dark-50: #b5bbc6;
$highlight: #d4fa08;
$text: rgb(46, 49, 56); 

// fonts
$sansSerif: Proxima Nova, sans-serif;
$serif: Athelas-Regular, serif;
