.dark-300 {
  color: $dark-300;
}
.linkcolor {
  color: $linkcolor;
}

.primary {
  color: $primary;
}

.textcolor {
  color: $text;
}

h1 {
  @extend .primary;
}

h1::after {
  content: ' ';
  background-image: linear-gradient(to left, #ffffff, $highlight);
  height: 0.08em;
  width: 100%;
  position: relative;
  display: inline-block;
}

a {
  @extend .linkcolor;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
    @extend .linkcolor;
    border-bottom: solid 2.5px $linkcolor;
  }
}

.siteTitle {
  a {
    border-bottom: none;
  }
}

.blog_post a {
  @extend .textcolor;
  border-bottom: solid 2.5px $linkcolor;
}

.border-linkcolor {
  @include bordercolor($linkcolor);
}
.border-dark-300 {
  @include bordercolor($dark-300);
}
