body {
  min-height: 100%;
  background-image: url("/svgs/background.svg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.posts, .header, footer, article {
  background-color: rgba(255,255,255,0.6);
}

@media only screen and (max-width:639px) {
  body {
    background-size: contain;
  }
}

// Content
#content {
  margin-top: 5%;
  min-height: 50vh;
}

// Mobile navigation
// hide mobile menu toggle on desktop
.main-nav {
  z-index: 1000;
  .icon {
  display: none;
  text-align: right;
  }
}

@media screen and (max-width: 670px) {
  .main-nav {
    ul {
      li {
        display: none;
      }
    }
    .icon {
      float: right;
      display: block;
    }
    &.responsive {
      position: absolute;
      background: #fff;
      width: 100%;
      padding: 1.5em;
      top: 0;
      left: 0;
      border: 1px solid $dark-300;
      z-index: 1000;
      ul {
        li {
          display: block;
        }
      }
      .icon {
        position: absolute;
        right: 1em;
        top: 1.5em;
      }
    }
  }
  img.logo {
    max-width: 100px;
  }
  .siteTitle {
    max-width: 280px;
    span {
      font-size: 0.825em;
    }
  }
}
// Image sizes
img[src*="#small"]{
  width: 400px;
  display: block;
  padding-top: 1em;
}

// Captions
figcaption h4 {
  font-size: initial;
  font-weight: initial;
}

// Header
@media only screen and (min-width: 671px){
  img.logo {
    max-width: 200px;
  }
}



// Sidebar
@media only screen and (max-width: 670px) {
  .post-wrapper {
    flex-direction: column;
  }
}
// Screenreader-only text
.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
// Home
.read-more-button {
  border: 1px solid $linkcolor;
  padding: 0.5rem;
}
.homepage-content {
  margin: 3rem 0;
  text-align: center;
}
.homepage-header {
  text-align: center;
}
